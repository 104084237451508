// exports.onServiceWorkerUpdateReady = () => {
//     const answer = window.confirm(
//         `This application has been updated. ` +
//             `Reload to display the latest version?`
//     );
//     if (answer === true) {
//         window.location.reload();
//     }
// };

export const onServiceWorkerUpdateReady = () => window.location.reload();


export const onRouteUpdate = ({ location }) => {
    // Check if the current path contains a hash fragment (e.g., #section-1)
    if (location && location.hash) {
        const targetId = location.hash.substring(1); // Remove the '#' symbol
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            // Scroll to the target section smoothly
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }
};

// In gatsby-browser.js
// exports.onRouteUpdate = function ({ location }) {
//     if (typeof window !== 'undefined') {
//         scrollToAnchor(location)
//     }
// }

// /**
//  *
//  * @desc - a function to jump to the correct scroll position
//  * @param {Object} location -
//  * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
//  */
// function scrollToAnchor(location, mainNavHeight) {
//     // Check for location so build does not fail
//     if (location && location.hash) {
//         // Use querySelector only if window is available
//         var item = document.querySelector(location.hash)
//         if (item) {
//             window.scrollTo({
//                 top: item.offsetTop - (mainNavHeight || 0),
//                 behavior: "smooth",
//             })
//         }
//     }
// }



// // In gatsby-browser.js
// export const onRouteUpdate = ({ location }) => {
//     if (typeof window !== 'undefined') {
//         scrollToAnchor(location);
//     }
// }

// /**
//  *
//  * @desc - a function to jump to the correct scroll position
//  * @param {Object} location -
//  * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
//  */
// function scrollToAnchor(location, mainNavHeight) {
//     // Check for location so build does not fail
//     if (location && location.hash) {
//         // Use querySelector only if window is available
//         var item = document.querySelector(location.hash);
//         if (item) {
//             window.scrollTo({
//                 top: item.offsetTop - (mainNavHeight || 0),
//                 behavior: "smooth",
//             });
//         }
//     }
// }

// export const onServiceWorkerUpdateReady = () => window.location.reload();